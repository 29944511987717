import React from 'react'
import {Link} from 'gatsby'
import Fade from 'react-reveal/Fade'

import Head from '../../util/head/Head'
import StyledLink from '../../components/styled-link/StyledLink'

import style from './NotFound.module.scss'

const NotFound = () => (
  <>
    <Head title="Not found" />
    <div className={style.notfound}>
      <Fade bottom distance="25%">
        <div className={style.content}>
          <div className={style.title}>
            <h2>Not found</h2>
          </div>
          <div className={style.link}>
            <Link to="/">
              <StyledLink>
                <span>Homepage</span>
              </StyledLink>
            </Link>
          </div>
        </div>
      </Fade>
    </div>
  </>
)

export default NotFound
